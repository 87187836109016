import React, { useEffect } from 'react';
import useTenancy from 'features/tenancy';
import { useParams } from 'react-router-dom';
import { parseToUUID, isUUID } from 'helpers/uuid';
import Map from './components/Map';
import GeneralSection from './components/GeneralSection';
import SimilarTenanciesSection from './components/SimilarTenanciesSection';
import ContactSection from 'components/ContactSection';
import HeaderSection from './components/HeaderSection';
import CarouselSection from './components/CarouselSection';
import OpenHouseSection from './components/OpenHouseSection';
import VirtualTourSection from './components/VirtualTourSection';
import Loader from 'components/Loader';
import styled from 'styled-components';
import useTenancies from 'features/tenancies';
import ReactGA from 'react-ga';
import getTenancyUrl from 'helpers/getTenancyUrl';

const TenancyWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const LoaderWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
`;

const Tenancy = () => {

	const {
		isTenancyLoading,
		isTenancyNotFound,
		tenancyData,
		loadTenancy
	} = useTenancy();
	const params = useParams();
	const {
		isTenanciesLoading,
		tenanciesData,
		loadTenancies
	} = useTenancies();

	useEffect(() => {
		let id = params.id;
		if (!isUUID(id)) {
			id = parseToUUID(id);
		}
		loadTenancy(id);
		loadTenancies();
	}, [params.id, loadTenancy, loadTenancies]);

	useEffect(() => {
		if (isTenanciesLoading || !tenancyData?.id) return;
		const id = tenancyData.id;
		ReactGA.event({
			category: 'Tenancy',
			action: 'Tenancy Viewed',
			label: id,
			nonInteraction: true
		});
	}, [isTenanciesLoading, tenancyData]);

	if (isTenancyLoading) {
		return (
			<LoaderWrapper>
				<Loader />
			</LoaderWrapper>
		);
	}

	if (isTenancyNotFound || tenancyData?.state === 'Unavailable') {
		return (
			<>Not found</>
		);
	}

	const propertyLink = (propertyId) => {
		const properties = [
			{
				id: "ce1a1649-d3bf-424e-a779-6b0e35ff4271",
				link: "/ejendomme/dalum",
			},
			{
				id: "111a0991-9c70-4945-93a1-288d1409677b",
				link: "/ejendomme/dalum",
			},
			{
				id: "4514963e-12df-4cdf-890f-f426c9e5dfad",
				link: "/ejendomme/dalum",
			},
			{
				id: "ce1a1649-d3bf-424e-a779-6b0e35ff4271",
				link: "/ejendomme/dalum",
			},
			{
				id: "9dc1035d-3871-45f9-b731-459ba4e66161",
				link: "/ejendomme/dalum",
			},
			{
				id: "d02781cb-2afe-4c17-8519-2f6af5597ef1",
				link: "/ejendomme/dalum",	
			},
			{
				id: "0308436b-811a-48fc-a5c2-c7157765b469",
				link: "/ejendomme/dalum",	
			},
			{
				id: "e40ef242-de4a-41b6-b466-631aa7faf3d1",
				link: "/ejendomme/dalum",	
			},
			{
				id: "e12ba367-0d6d-4956-932c-d4235eb14042",
				link: "/ejendomme/dalum",	
			},
			{
				id: "4514963e-12df-4cdf-890f-f426c9e5dfad",
				link: "/ejendomme/dalum",	
			},
			{
				id: "ce1a1649-d3bf-424e-a779-6b0e35ff4271",
				link: "/ejendomme/dalum",	
			},
			{
				id: "111a0991-9c70-4945-93a1-288d1409677b",
				link: "/ejendomme/dalum",	
			},
			{
				id: "27382278-e49e-4b12-8179-d8ea81d1c250",
				link: "/ejendomme/lysningen",	
			},
			{
				id: "f7b60794-be2c-49a2-be42-810b05913012",
				link: "/ejendomme/smedetoften",	
			},
			{
				id: "213ffaa6-d6e4-4895-9cd6-a063b7fb9736",
				link: "/ejendomme/telegrafkollegiet",	
			}

		];
		const option = properties.find((item) => item.id === propertyId);
		if (option) {
			return option.link;
		} else {
			return false;
		}
	};
	
	return (
		<TenancyWrapper>
			<OpenHouseSection
				isLoading={isTenanciesLoading}
				openHouses={tenancyData.openHouses}
			/>
			<CarouselSection
				classification={tenancyData.classification}
				types={tenancyData.types}
				images={tenancyData.images}
				floorplans={tenancyData.floorPlans}
				isReserved={tenancyData.state === 'Reserved' ? true : false}
			/>
			<HeaderSection
				title={tenancyData.title}
				rooms={tenancyData.rooms}
				size={tenancyData.size.value}
				street={tenancyData.address.street}
				city={tenancyData.address.city}
				postnumber={tenancyData.address.zipCode}
				availableFrom={tenancyData.availableFrom}
				contactName={tenancyData.responsibleEmployee?.name}
				contactPhone={tenancyData.responsibleEmployee?.phoneNumber}
				contactEmail={tenancyData.responsibleEmployee?.email}
				contactPicture={tenancyData.responsibleEmployee?.picture}
			/>
			<GeneralSection
				classification={tenancyData.classification}
				tenancyDescription={tenancyData.descriptions.tenancy}
				areaDescription={tenancyData.descriptions.area}
				propertyDescription={tenancyData.descriptions.property}
				street={tenancyData.address.street}
				city={tenancyData.address.city}
				postnumber={tenancyData.address.zipCode}
				rooms={tenancyData.rooms}
				size={tenancyData.size.value}
				availableFrom={tenancyData.availableFrom}
				monthlyRent={tenancyData.monthlyRent.value}
				prepaidRent={tenancyData.prepaidRent.amount.value}
				deposit={tenancyData.deposit.amount.value}
				petsAllowed={tenancyData.additionalDetails.petsAllowed}
				elevator={tenancyData.propertyFacilities.includes('Elevator') ? true : false}
				balcony={tenancyData.tenancyFacilities.includes('Balcony') ? true : false}
				heating={tenancyData.expenses.some(e => e.type === 'Heating') ? tenancyData.expenses.filter(e => e.type === 'Heating')[0].amount.value : null}
				water={tenancyData.expenses.some(e => e.type === 'Water') ? tenancyData.expenses.filter(e => e.type === 'Water')[0].amount.value : null}
				propertyLink={propertyLink(tenancyData.propertyId)}
				propertyId={tenancyData.propertyId}

			/>
			{tenancyData.virtualTour.url !== '' && <VirtualTourSection virtualTourLink={tenancyData.virtualTour.url} />}
			{tenancyData.address.coordinates?.longitude && tenancyData.address.coordinates?.latitude && <Map lng={tenancyData.address.coordinates.longitude} lat={tenancyData.address.coordinates.latitude} locations={tenancyData.locations} />}
			<SimilarTenanciesSection tenancy={tenancyData} tenancies={tenanciesData} loading={isTenanciesLoading} />
			<ContactSection/>
		</TenancyWrapper>
	);
};

export default Tenancy;